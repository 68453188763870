import React, { useState, useMemo } from 'react';
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, message, Avatar, Dropdown, Space, Alert } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import Settings from '../../dependencies/custom/settings';
import useAdd from '../../hooks/add';
import ValuesStore from '../../store/values-store';
import { Offline, Online } from "react-detect-offline";
const { Header, Content, Sider, Footer } = Layout;
const items1 = ['1', '2', '3'].map((key) => ({
    key,
    label: `nav ${key}`,
}));

const App = () => {
    const valuesStore = ValuesStore();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('1');
    const [tokenValid, setTokenValid] = useState(false);
    const [pages, setPages] = useState([]);
    const [avatar, setAvatar] = useState(undefined);
    const [schoolDetails, setSchoolDetails] = useState();
    const qh = utils.getHString();
    const siderWidth = 208;
    const [breadcrumb, setBreadcrumb] = useState([]);
    const navBarColor = '#f5f5f5';
    const siderColor = '#ece6e6';
    // const siderColor = 'fff';
    const add = useAdd('tables_metadata', 'table_name');
    const {
        token: { colorBgContainer },
    } = theme.useToken();


    function changePage(page, key) {
        setSelectedMenuItem(key);
        navigate(page);
    }

    // function changePassword() {
    //     add.setTblName('admin');
    //     add.setShowModal(true);
    //     add.setSaveCompleted(false);
    // }

    // async function addOnOk() {
    //     const data = { ...add.record, token: utils.getCookie('token') };
    //     const res = await utils.requestWithReauth('post', `${Settings.backend}/change_admin_password`, null, data);
    //     message.success(res.msg);
    //     if (res.status === 'Ok') {
    //         add.setShowModal(false);
    //         utils.logout(navigate);
    //     }
    // }


    useMemo(() => {
        const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
        if (institution) {
            setSchoolDetails(JSON.parse(institution));
        }


        if (qh['page']) {
            setSelectedMenuItem(qh['page']);
        }
        getAssignedPages();
        getAssignedPemissions();
        utils.verifyToken(navigate, setTokenValid);
        // console.log(utils.getUrlFileName(),);
        currentBreadCrumb();

    }, [window.location.hash, add.saveCompleted, valuesStore['settings']]);

    function currentBreadCrumb() {
        const currPage = valuesStore.getValue('permitted_routes')?.filter(v => {
            return v.path == utils.getUrlFileName()
        });

        setBreadcrumb([{ title: 'Finance' }, { title: currPage[0]?.description }])
    }

    async function getAssignedPages() {
        const token = utils.getCookie('token');
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_assigned_pages`, null, { token });
        valuesStore.setValue('permitted_routes', res);
        if (Array.isArray(res)) {
            const pages = res?.map(r => {
                return {
                    key: r?.id,
                    icon: <i className={`${r?.icon}`} />,
                    label: <label onClick={e => changePage(r?.path, r?.id.toString())}>{r?.description}</label>,
                }
            });
            setPages(pages);
        } else {
            message.error(res.msg);
        }
    }

    async function getAssignedPemissions() {
        const token = utils.getCookie('token');
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_assigned_permissions`, null, { token });
        valuesStore.setValue('permissions', res);
    }


    function menu() {
        return <Menu
            className='h-scrolling-item'
            mode="inline"
            selectedKeys={[selectedMenuItem]}
            items={pages}
        />
    }

    return (
        <Layout>
            <Header
                className={`d-flex justify-content-between sticky-top border-bottomx racoh-red`}
                style={{
                    background: `${navBarColor}`,
                }}
            >
                <div className='d-flex'>
                    <div className="demo-logo" >{schoolDetails?.logo && <img src={`${Settings.root}/${schoolDetails?.logo}`} width={150} />}</div>
                    <label style={{ color: 'rgb(140, 11, 59)', fontSize: '15px' }} className='fw-boldx text-uppercase text-white'>{valuesStore.getArrayObjectsValue('settings', 'prop', 'APP_NAME')?.value}</label>
                    <label className='fw-boldx text-white'> {valuesStore.getArrayObjectsValue('settings', 'prop', 'APP_VERSION')?.value}</label>
                </div>
                <div className='d-flex'>
                    <Dropdown menu={{
                        items: [
                            {
                                key: '1',
                                label: (
                                    <a onClick={e => navigate('./change_password')}>
                                        <i className='fas fa-sign-out-alt' /> Change Password
                                    </a>
                                ),
                            },
                            {
                                key: '2',
                                label: (
                                    <a onClick={e => utils.logout(navigate)}>
                                        <i className='fas fa-sign-out-alt' /> Sign out
                                    </a>
                                ),
                            }
                        ]
                    }} arrow>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <Avatar size='large' icon={<UserOutlined />} src={avatar} />
                            </Space>
                        </a>
                    </Dropdown>
                </div>
            </Header>
            <Content
                style={{
                    padding: '0 48px',
                }}
            >
                <Breadcrumb
                    style={{
                        margin: '16px 0',
                    }}
                    items={breadcrumb}
                />

                <Layout
                    style={{
                        padding: '24px 0',
                        background: colorBgContainer,
                        // borderRadius: borderRadiusLG,
                    }}
                >
                    <Sider
                        style={{
                            background: colorBgContainer,
                        }}
                        width={200}
                    >
                        {menu()}
                    </Sider>
                    <Content
                        className='rounded-5'
                        style={{
                            padding: '0 24px',
                            minHeight: 280,
                        }}
                    >
                        <div className=''>
                            {tokenValid && <Outlet />}
                        </div>
                    </Content>
                </Layout>
            </Content>
            <Footer style={{ textAlign: 'center' }}>AAMUSTED © {new Date().getFullYear()}</Footer>
        </Layout>
    );
};
export default App;

