import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './css/mdb.min.css';
import './font/fontawesome/css/all.min.css'
import './css/custom/animations.css';
import './css/custom/tweeks.css';
import './App.css';

import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import usePhoneBaseMenu from './js/components/phoneBaseMenu';
import useWindowDimensions from './js/components/screensize';

import SettingsStore from './js/store/settings-store';
import ValuesStore from './js/store/values-store';
import utils from './js/dependencies/custom/react-utilities'
import IndexedDB from './js/dependencies/custom/indexeddb'
import Settings from './js/dependencies/custom/settings';

//admin paths
import AdminHome from './js/pages/admin/home';
import BankAccounts from './js/pages/admin/bank_accounts';
import PayItems from './js/pages/admin/pay_items';
import StudentBills from './js/pages/admin/student_bills';
import Transactions from './js/pages/admin/transactions';
import AdminSetting from './js/pages/admin/settings';
import Students from './js/pages/admin/students';
import AdminFiles from './js/pages/admin/admin_files';
import AdminPerms from './js/pages/admin/admin_perms';
import AdminRoles from './js/pages/admin/admin_roles';
import Admin from './js/pages/admin/admin';
import AdminRoleFilesLink from './js/pages/admin/admin_role_files_link';
import AdminRolePerm from './js/pages/admin/admin_role_perm';
import AdminRoleLink from './js/pages/admin/admin_role_link';
import DebtorsBalance from './js/pages/admin/debtors_balance';
import ManualEntry from './js/pages/admin/manual_entry';
import ProcessEntries from './js/pages/admin/process_entries';
import Login from './js/pages/admin/login';
import NotFound from './js/pages/admin/404'
import ProgramSessionCodes from './js/pages/admin/program_session_codes';
import BillComponents from './js/pages/admin/bill_components';
import BillTemplates from './js/pages/admin/bill_templates';
import BillBreakdownReport from './js/pages/admin/bill_breakdown_report';
import ChangePassword from './js/pages/admin/change_password';
import IndexNumberStarter from './js/pages/admin/index_number_starter';
import Dashboard from './js/pages/admin/dashboard';
import InitRecoverPassword from './js/pages/admin/init_recover_password';
import CompleterecoverPassword from './js/pages/admin/complete_recover_password';
import Topaz from './js/pages/admin/topaz';
import PriorityBreakdownReport from './js/pages/admin/priority_breakdown_report';
import ManualTxn from './js/pages/admin/manual_txn';


function App() {
  const settingsStore = SettingsStore();
  const valuesStore = ValuesStore();
  const navigate = useNavigate();
  const location = useLocation();
  const { vpWidth } = useWindowDimensions();
  const phoneBaseMenu = usePhoneBaseMenu();
  // const [homePageCardSize, setHomePageCardSize] = useState('3');  
  // const [bannerType, setBannerType] = useState('jumbotron');

  const fetchItems = [
    {
      method: 'post',
      sql: 'SELECT * FROM settings WHERE is_public = 1',
      url: `${Settings.backend}/bootstrap_others`,
      storeName: 'configSettings',
    }
  ];

  const newLocation = useLocation();
  const urlFileName = utils.getUrlFileName();

  function hasRoute() {
    const timer = setInterval(() => {
      const routesAvailable = valuesStore.getValue('permitted_routes');
      if (routesAvailable.length > 0) {
        clearInterval(timer);
        const routes = valuesStore.getArrayObjectsValue('permitted_routes', 'path', urlFileName);
        if (Object.keys(routes).length <= 0) {
          navigate(-1);//go back to the previous page if the requested routes is not found in what the use can access
        }
      }
    }, 1000);
  }

  useEffect(() => {
    if (!(['/login', '/init_psd_recovery', '/complete_recover_password'].includes(location.pathname))) {
      utils.bootstrap(valuesStore, settingsStore, [settingsStore.pay_items, settingsStore.programs, 
        settingsStore.settings, settingsStore.student_bill_components, settingsStore.tables_metadata]);
    }
    // utils.bootstrapOthers(valuesStore, fetchItems);
    // hasRoute();

    return () => {
      //clean up here
    };
  }, [newLocation, valuesStore['loggedIn']]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/init_psd_recovery' element={<InitRecoverPassword />} />
        <Route path='/complete_recover_password' element={<CompleterecoverPassword />} />
        {/* <Route path='id_card' element={<IdCard />} /> */}
        <Route path='/admin' element={<AdminHome />}>
          <Route path='bank_accounts' element={<BankAccounts />} />
          <Route path='pay_items' element={<PayItems />} />
          <Route path='student_bill' element={<StudentBills />} />
          <Route path='transactions' element={<Transactions />} />
          <Route path='settings' element={<AdminSetting />} />
          <Route path='students' element={<Students />} />

          <Route path='admin' element={<Admin />} />
          <Route path='admin_files' element={<AdminFiles />} />
          <Route path='admin_roles' element={<AdminRoles />} />
          <Route path='admin_perms' element={<AdminPerms />} />
          <Route path='admin_role_files_link' element={<AdminRoleFilesLink />} />
          <Route path='admin_role_link' element={<AdminRoleLink />} />
          <Route path='admin_role_perm' element={<AdminRolePerm />} />
          <Route path='debtors_balance' element={<DebtorsBalance />} />
          <Route path='manual_entry' element={<ManualEntry />} />
          <Route path='process_entries' element={<ProcessEntries />} />
          <Route path='program_session_codes' element={<ProgramSessionCodes />} />
          <Route path='bill_components' element={<BillComponents />} />
          <Route path='bill_templates' element={<BillTemplates />} />
          {/* <Route path='bill_breakdown_report' element={<BillBreakdownReport />} /> */}
          <Route path='bill_breakdown_report' element={<PriorityBreakdownReport />} />
          <Route path='change_password' element={<ChangePassword />} />
          <Route path='index_number_starter' element={<IndexNumberStarter />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='topaz_report' element={<Topaz />} />
          <Route path='txn_entry' element={<ManualTxn />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />

      </Routes>

      {/* {vpWidth < 700 && (['/vpayment', '/', '/terms_of_service', '/privacy_policy', '/sell'].includes(window.location.pathname) ? '' : phoneBaseMenu.baseMenu('phoneBaseMenu'))} */}
    </>

  )
}

//disable auto zoom in on iphone and ipad. iphone and ipad automatically zoom in when text size is less than 16px
if (utils.checkIsIOS()) {
  utils.addMaximumScaleToMetaViewport();
}

export default App;
